export default {
    init() {
        const button = document.querySelector('.printBtn');

        button?.addEventListener('click', () => {
            document.querySelector('body')?.classList.add('print');
            window.print();
        });

        window.onafterprint = () => {
            document.querySelector('body')?.classList.remove('print');
        };
    }
};
